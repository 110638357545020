import React, { useState, useEffect } from 'react';
import './Success.css';

function Success() {
    return <div className="success">
            <div className="caption">
                <p>Thank You!</p>
            </div>
        </div>;
}

export default Success;