import React, { useContext } from "react";
import { setSession } from './session';

const Domain             = 'stage.nacris.eu';
const BackendUrl         = 'https://api.' + Domain;

async function ourfetch(path, response, jsondata = null, method = "POST") {
    const json = (jsondata === null) ? null : JSON.stringify(jsondata);
    if (method.toUpperCase() === 'GET') {
        rawfetch(path, response, json, method, null);
    } else {
        rawfetch(path, response, json, method,
            { 'Content-Type' : 'application/json' });
    }
} 

async function rawfetch(path, response,  rawdata = null, method = "POST", headers = null) {
    let request = {
        method  : method, mode: 'cors', credentials: 'include', // cache: 'no-cache', redirect: 'follow', referrerPolicy: 'no-referrer', 
    };
    if (headers !== null) {
        request.headers = headers;
    }
    if (rawdata !== null) {
        request.body    = rawdata;
    }
    // console.log('fetching: ' + path + ', request: ' + JSON.stringify(request));
    fetch(BackendUrl + path, request).then(async resp => {
        const json = await resp.json();
        // console.log('fetch response: ' + JSON.stringify(json));

        // please note, that json.code is application level status code, not an http response code;
        // across the application there is a principle that all responses received from the backend with 
        // negative application status codes will terminate the session. Same logic holds for backend:
        // with sending negative applicaipon status code the backend will always set all cookies 
        // to expire immediately, effectively temrinating the user session.
        setSession(json);
        response(json);
    }).catch(error => {
        console.log(error);
    });
}

function addFieldValue(elem, json, name) {
    elem.target.querySelectorAll(`.field.${name} .value`).forEach((item) => {
        let data = item.dataset;
        if (data.value.length === 0 || (data.check && (data.value !== 'true'))) {
            return;
        }
        if (data.singl) {
            json[name] = data.value;
            return;
        }
        if (data.key  ) {
            if (json[name]) {
                json[name][data.key] = data.value;
            } else {
                json[name] = { [data.key] : data.value };
            }
        } else {
            if (json[name]) {
                json[name].push(data.value);
            } else {
                json[name] =   [data.value];
            }
        }
    });
}

function toFormJSON(elem, list) {
    let json = { }; list.forEach((name) => {
        addFieldValue(elem, json, name);
    });
    return json;
}


export { ourfetch, rawfetch, toFormJSON };