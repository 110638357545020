import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Success from './Success';
import Questionnaire from './Form';
import Default from './Default';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import { BrowserRouter, Routes, Route } from "react-router-dom";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="*" element={<App/>}>
                  <Route path="q1/success" element={<Success/>}/>
                  <Route path="q1" element={<Questionnaire/>}/>
                  <Route path="*" element={<Default/>}/>
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
