import React, { useState, useEffect } from 'react';

import { Link, useNavigate    } from "react-router-dom";
import { FontAwesomeIcon         } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare, faToggleOn, faToggleOff, faCircleDown, faRulerHorizontal  } from "@fortawesome/free-solid-svg-icons";

import { ourfetch, rawfetch, toFormJSON } from 'common/util/globals';

import { API as GraphAPI, graphqlOperation } from 'aws-amplify';
import {   createQuestionnaire } from './graphql/mutations';
import { onCreateQuestionnaire } from './graphql/subscriptions';

import banner from './banner_desktop.png';

import uuid from 'react-uuid';

import './Form.css';

function Checkbox(props) {
    const [state, setState] = useState(false);

    function toggle(e) {
        if (props.max_count > 0 && props.count >= props.max_count && ! state) {
            return;
        }
        setState( ! state); props.addCount( ! state ? +1 : -1);
    }
    return (
        <div className="checkbox item" name={props.name} data-disabled={props.max_count > 0 && props.count >= props.max_count && ! state} data-checked={state} onClick={toggle}>
            <FontAwesomeIcon className="value" data-check={true} data-key={props.name} data-value={state} icon={state ? faCheckSquare : faSquare}/>
            <label>{props.label}</label>
        </div>
    );
}

// shuffle array
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [ a[i], a[j] ] = [ a[j], a[i] ];
    }
    return a;
}

function Form() {
    const [count, setCount] = useState(0);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [freetext, setFreeText] = useState('');

    const navigate = useNavigate();

    const max_count = 0;

    function addCount(value) {
        setCount(count + value);
    } 

    const pain_list = [ 
        { code:  1, text: 'Large unpredictable expenses'},
        { code:  2, text: 'Making unnecessary purchases'},
        { code:  3, text: 'Feeling that spendings are out of control'},
        { code:  4, text: 'Feeling being ripped off all the time'},
        { code:  5, text: 'Making emotional purchases'},
        { code:  6, text: 'Can\'t predict my future expenses'},
        { code:  7, text: 'Living pay-check to pay-check'},
        { code:  8, text: 'Not enough cash by the end of the month'},
        { code:  9, text: 'Psychological stress at the end of the month'},
        { code: 10, text: 'My habit of spending more than making'},
        { code: 11, text: 'Having to continuously roll over debts'},
        { code: 12, text: 'Unable to put any money aside'},
        { code: 13, text: 'Feeling depressed, when viewing bank statements'},
        { code: 14, text: 'Making "once a year" large purchases way too often'},
        { code: 15, text: 'Lack of clarity about services I am regularly billed for'},
        { code: 16, text: 'Having no savings for retirement'},
        { code: 17, text: 'Not being able to finance a decent vacation'},
        { code: 18, text: 'Frequent arguments with my spouse about money'},
        { code: 19, text: 'Having to manage too many accounts in different banks'},
        { code: 20, text: 'Having to analyze too many transactions to understand my spendings'},
        { code: 21, text: 'Having no clue, if my net worth is going up or down'},
    ];

    async function submit(e) {
        e.preventDefault();
        // console.log('submit clicked');

        let json = toFormJSON(e, ['boxlist', 'mailaddr', 'freetext']);

        // console.log('payload: ' + JSON.stringify(json) );

        if (Object.keys(json).length === 0) {
            setError(true);
            return;
        }

        let sk = sessionStorage.getItem('sk') ?? uuid();
        let  q = { pk: 'questionnaire1',  sk: sk, answers: JSON.stringify(json) };
        // console.log('params: ' + JSON.stringify(q));
        await GraphAPI.graphql(graphqlOperation(createQuestionnaire, { input: q }))
            .then ((response) => {
                // console.log('success:' + JSON.stringify(response));
                sessionStorage.setItem('sk', response.data.createQuestionnaire.sk);
            })
            .catch((response) => {
                console.log('error: '  + JSON.stringify(response));
            });

        window.gtag("event", "purchase", {
            transaction_id: "T_12345", value: 119.99, tax: 19.99, shipping: 5.99, currency: "EUR",
            items: [ { item_id: "SKU_12345", item_name: "Market Research on Personal Finance Planning" } ]
        });

        navigate('success');
/*
        rawfetch(`/submit/pains`, (response) => {
            console.log('response received: ' + JSON.stringify(response));
            if (response.code < 0) {
               // error.report(response.code, 'Critical error occurred during fetch /upload');
            } else {
             //   navigate(BasePath + '4');
            }
        }, JSON.stringify(json));
        //navigate(BasePath + '3');
*/
    }
/*
    useEffect(() => {
        const subscription = GraphAPI.graphql(
            graphqlOperation(onCreateQuestionnaire)
        ).subscribe({ next: (data) => {
            // console.log('Questionnaire created: ' + JSON.stringify(data.value.data));
        } } );
        return () => { subscription.unsubscribe() };
    }, []);
*/

    
    return (
        <form className="form" onSubmit={submit}>
            <div className="banner">
                <img/>
            </div>

            <div className="caption welcome">
                <p>We noticed Your interest in <b>Personal&nbsp;Finance Planning</b></p>
            </div>

            <div className="next">
                <a href="#more-info"><FontAwesomeIcon icon={faCircleDown}/></a>
            </div>

            <div id="more-info" className="caption">
                <p className="just">Our mission is to solve financial planning struggle for millions of people worldwide</p>
                <p className="just">This page is a survey, where we'd like to have Your input for our expense planner app</p>
                <p className="just">Please spend a couple of minutes with us and answer to one question below</p>
            </div>

            <div className="next">
                <a href="#question"><FontAwesomeIcon icon={faCircleDown}/></a>
            </div>

            <div id="question" className="content">    
                <p className="just">What upsets You most in Your personal finance and shopping practice?</p>
                <p className="just hint"><i>Please select up to 5 items from the list</i></p>
                
                <div className="just field boxlist">
                     {pain_list.map((item) => { // todo: shuffle the list to avoid false significant measurements, in case the users decide to just select the top 5 items all the time
                        return <Checkbox key={item.code} addCount={addCount} count={count} max_count={max_count} name={`cb${item.code}`} label={item.text}/>
                     })}
                </div>

                <hr/>

                <div className="paragraph freetext field">
                    <div className="item">
                        <textarea className="value" data-singl={true} data-value={freetext} value={freetext} onChange={(e) => setFreeText(e.target.value)} rows="4" placeholder="Share more thoughts here ..."></textarea>
                    </div>
                </div>
{/*
                <div className="paragraph mailtext">
                    <span>We would be grateful to have Your contact email, so that we could reach out to You later, during our further market research and validation of product-market fit</span>
                </div>
                    */}
                <p className="mailtext">With your email, we can also provide You an early access to the product we build</p>

                <div className="paragraph mailaddr field">
                    <div className="item">
                        <input className="value" type="email" data-singl={true} data-value={email} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email ..."></input>
                    </div>
                </div>
                
            </div>
            <div className="error">
                <span data-hidden={ ! error}>Please select the answers!</span>
            </div>
            <div className="control">
                <button type="submit">Submit</button>
            </div>
        </form>
    );
}

export default Form;
